@import '../../inc.scss';

.infoSplash {
    margin: 5rem .2rem;
    text-align: center;

    $sizes: (
        (l, 4rem, 6rem),
        (m, 2.5rem, 4rem),
        (s, 2rem, 3rem),
    );

    &-icon {
        @each $name, $touch, $pad in $sizes {
            &__size-#{$name} {
                @include size($touch);

                @include respond-to(pad) {
                    @include size($pad);
                }
            }
        }
    }

    h1 {
        margin-bottom: .5rem;
    }
}
