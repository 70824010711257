@import '../../inc.scss';

$animation-fx: cubic-bezier(.4, 0, .2, 1);

.menu {
    &-content {
        position: sticky;
        top: var(--header-height);
        bottom: 0;
        left: 0;

        width: var(--menu-width);
        min-width: var(--menu-width-desktop);
        height: 100%;

        overflow: auto;

        /**
         * Pad and desktop
         */
        @include respond-to(pad) {
            height: auto;
            min-width: unset;

            background: transparent;
        }
    }

    /**
     * Touch only
     */
    @include respond-to(pad, max) {
        position: fixed;
        top: var(--header-height);
        bottom: 0;
        z-index: $zIndex-menuContent;

        transform: translateX(-110%);
        transition: transform .2s $animation-fx;

        box-shadow: 0 0 9px 3px rgba($color: #000000, $alpha: .2);

        &__open {
            transform: translateX(0);
        }

        &-overlay {
            background: rgba(0, 0, 0, .5);
            transition: opacity .2s $animation-fx;
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            pointer-events: none;
            z-index: $zIndex-menuOverlay;

            &__open {
                pointer-events: auto;
                opacity: 1;
            }
        }

        &-content {
            background: var(--color-body);
        }
    }

    &-item {
        @include flex(row);
        align-items: center;
        padding: 1rem .8rem;
        color: var(--color-menu-item-text);

        transition:
            color .1s $animation-fx,
            background .1s $animation-fx;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
            background: var(--color-menu-item-hover-background);
            color: var(--color-menu-item-hover-text);
        }

        @include respond-to(desktop) {
            border-radius: 28px 0 0 28px;
        }

        &--icon {
            @include size(1.5rem);
        }

        &--label {
            margin-left: .8rem;

            @media (min-width: $breakpoint-pad) and (max-width: #{$breakpoint-desktop - 1}) {
                display: none;
            }
        }
    }

    hr {
        border: none;
        border-top: 1px solid var(--color-menu-horizontal-line);
        margin: .4em 0;
    }
}
