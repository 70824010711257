@use "sass:math";

@import '../../inc.scss';

.head {
    position: fixed;
    z-index: $zIndex-siteHeader;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    background: var(--color-header);
    color: #ffffff;

    &-container {
        display: grid;
        grid-template-columns: var(--header-menu-side) 1fr calc(var(--header-height) * 2);
        justify-content: space-between;

        height: var(--header-height);

        @extend %container;

        margin: 0 auto;
        line-height: 0;
    }

    &-left,
    &-user {
        @include flex(row);
    }

    $prop: .6;
    $icons-size: calc(var(--header-height) * #{$prop});
    $padding: calc(var(--header-height) * #{math.div(1 - $prop, 2)});

    &-logo,
    &--link {
        padding: $padding;
    }

    &--link {
        display: block;
        cursor: pointer;

        &:hover {
            background: rgba($color: #000000, $alpha: .1);
        }

        &__user {
            &-avatar {
                object-fit: cover;
                border-radius: 50%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2rem' height='2rem' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z' /%3E%3C/svg%3E");
            }
        }
    }

    &--link__user-avatar,
    svg {
        @include size($icons-size);
    }

    &-left {
        grid-column: 1 / 2;
    }

    &-back {
        grid-column: 2 / 3;
    }

    &-user {
        grid-column: 3 / 4;
        justify-content: end;
    }
}
