a {
    color: inherit;
    text-decoration: none;
}

a:focus,
a:active,
button::-moz-focus-inner,
button:focus,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
select::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner {
    border: none !important;
    outline: none !important;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000000;
}
