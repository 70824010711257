@import '../../inc.scss';

.footer {
    background: #424242;
    color: var(--color-gray-lighter);

    &-container {
        @extend %container;
        width: 100%;
        padding: 2em 1em;

        @include flex(column);
        justify-content: space-between;

        @include respond-to(pad) {
            flex-direction: row;
        }
    }

    &-menu {
        @include flex(column);
        order: 0;

        line-height: 1.8rem;

        @include respond-to(pad) {
            flex-direction: row;
            align-items: center;
            line-height: 2.5rem;
            margin-bottom: 0;
        }
    }

    &-logo {
        display: none;

        @include respond-to(pad) {
            display: block;
            margin-right: 1rem;
        }
    }

    ul {
        @include flex(column, wrap);
        list-style: none;
        margin-bottom: 1rem;

        @include respond-to(pad) {
            flex-direction: row;
            margin-bottom: 0;
        }
    }

    li {
        margin-bottom: 0;
        margin-right: 1rem;
    }

    a {
        color: inherit;
        text-decoration: none;
        white-space: nowrap;
    }

    &-copyright {
        order: 1;

        @include respond-to(pad) {
            align-self: center;
        }
    }

    .body__compact & {
        display: none;
    }
}
