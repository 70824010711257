@import '../../inc.scss';

.main {
    flex: 1 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: var(--header-height);

    &-container {
        @include flex(row);

        @extend %container;

        width: 100%;

        [data-loading="true"] & {
            justify-content: center;
        }

        main {
            position: relative;
            flex: 1 0;
            background: var(--color-background);
            color: var(--color-text);
            max-width: 100%;

            @extend %theme-contrasted-animation;

            @include respond-to(pad) {
                box-shadow: 0 0 9px 3px rgba(0, 0, 0, .1);
            }
        }
    }
}
