@use "sass:math";

@import '../Progress/style.scss';

$duration: 1.4s;
$offset: 187;

.spinner {
    animation: rotator $duration linear infinite;

    &-path {
        transform-origin: center;
        animation: dash $duration ease-in-out infinite;
    }

    &.progress__auto &-path {
        animation:
            dash $duration ease-in-out infinite,
            colors ($duration * 4) ease-in-out infinite;
    }

    &-wrap {
        @include flex(column);
        justify-content: center;
        align-items: center;

        margin: 4rem 0;

        &--subtitle {
            margin-top: 1rem;
            text-align: center;
            color: var(--color-label);
        }
    }
}

@keyframes rotator {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes colors {
    0%,
    100% {
        stroke: #4285f4;
    }

    25% {
        stroke: #de3e35;
    }

    50% {
        stroke: #f7c223;
    }

    75% {
        stroke: #1b9a59;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: math.div($offset, 4);
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}
