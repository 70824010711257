$zIndex-toast: 1970;

$zIndex-modalWindow: 1951;
$zIndex-modalWrapper: 1950;

$zIndex-photoViewer: 1930; // not used, but reserved for tracking

$zIndex-siteHeader: 1900;

$zIndex-menuContent: 1710;
$zIndex-menuOverlay: 1700;

$zIndex-dropDownButton: 1600;

$zIndex-floatingHeader: 1500;

// z-index: 1000 -- leaflet

$zIndex-ratingBase: 440;

$zIndex-sight-gallery-grid-content: 50;
$zIndex-sight-gallery-grid-photo: 49;

$zIndex-usualElement: 40;
