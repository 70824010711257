@import './reset.scss';
@import './mixins.scss';
@import './sizes.scss';
@import './colors.scss';
@import './tippy.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::after,
::before {
    box-sizing: border-box;
}

body {
    font: {
        family: "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
        size: 15px;
    }

    background: var(--color-body);

    @extend %theme-contrasted-animation;
}

html,
body,
#root {
    height: 100%;
}

h1,
h2,
h3,
h4 {
    margin: .3rem 0;
}

@mixin header($tfz, $dfz, $lh) {
    font-size: $tfz;
    line-height: $lh;

    @include respond-to(pad) {
        font-size: $dfz;
    }
}

h1 {
    @include header(1.4rem, 2rem, 1.4);
}

h2 {
    @include header(1.2rem, 1.7rem, 1.2);
}

h3 {
    @include header(1rem, 1.5rem, 1.2);
}

h4 {
    @include header(.9rem, 1.2rem, 1.2);
}

a,
label {
    // Отключение синей подложки при тапе на элемент
    -webkit-tap-highlight-color: transparent;
}

body.ReactModal__Body--open {
    overflow: hidden;
}

@import './map-overrides';
