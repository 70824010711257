@import '../../inc.scss';

.home-page {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 150px;

        z-index: -1;
        pointer-events: none;
    }

    &--header {
        padding: 1rem 3% .3rem;
    }

    &--stats {
        margin: 1rem auto;

        @include respond-to(pad) {
            max-width: 70%;
        }

        @include respond-to(desktop-wide) {
            @include flex(row);
            max-width: unset;
        }

        &-item {
            @include flex(row);
            align-items: center;

            padding: 1.2rem .8rem;

            &--content {
                flex: 1;
                width: 70%;

                h3 {
                    font-size: 1.2rem;
                }
            }

            &--count {
                font-size: 2.2rem;
                width: 30%;
                text-align: center;

                @include respond-to(touch) {
                    font-size: 2.7rem;
                }

                @include respond-to(pad) {
                    font-size: 3rem;
                }

                @include respond-to(desktop) {
                    font-size: 3.2rem;
                }

                @include respond-to(desktop-wide) {
                    font-size: 3.5rem;
                }
            }

            @include respond-to(pad) {
                padding: 1.3rem 1.5rem;
                flex-direction: row;

                /* &:nth-child(2n) {
                    flex-direction: row-reverse;

                    .home-page--stats-item--content {
                        text-align: right;
                    }
                } */
            }

            @include respond-to(desktop-wide) {
                flex-direction: column-reverse !important;
                flex: 1;

                &--count,
                &--content {
                    width: unset;
                }

                &--content {
                    text-align: center !important;
                }
            }
        }
    }

    &--recent {
        .sight-gallery--head {
            display: none;
        }
    }

    &--advice-add {
        text-align: center;
        padding: 1.5rem .8rem 0;

        &:last-child {
            padding-bottom: 1.5rem;
        }

        @include respond-to(pad) {
            padding: 2rem 1rem 0;

            &:last-child {
                padding-bottom: 2rem;
            }
        }

        h2 {
            font-size: 1.2rem;
        }

        a {
            display: inline-block;
            margin: .5rem 0;
        }

        &-tip {
            color: gray;
            font-size: .7rem;
        }
    }
}
