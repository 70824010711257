@import './sizes.scss';
@import './classes.scss';
@import './mixins.scss';

%container {
    @include respond-to(pad) {
        max-width: var(--max-width-container);
        margin: 0 auto;

        transition: max-width .1s ease-in-out;
    }

    #{$class-body-wide} & {
        max-width: 100vw;

        @include respond-to(desktop) {
            max-width: 90vw;
            min-width: calc(var(--breakpoint-desktop) - 10px);
            margin: 0 auto;
        }

        /* @include respond-to(desktop-wide) {
            max-width: calc(var(--breakpoint-desktop-wide) - 40px);
        } */
    }

    /* @include respond-to(desktop-wide) {
        max-width: var(--breakpoint-desktop-wide);
    } */
}

%hide-scrollbar {
    // Firefox 64+
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        width: 8px;
    }
}

%nowrap-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

%user-text {
    white-space: pre-wrap;
}
