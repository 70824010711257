$color-primary-bg: #009688;
$color-primary-fg: #ffffff;
$color-secondary-bg: #448aff;
$color-secondary-fg: #000000;
$color-default-bg: #efefef;
$color-default-fg: #000000;
$color-label-fg: #787878;

%theme-contrasted-animation {
    transition: background .15s linear, color .15s linear;
}
