@import '../inc';

:root {
    // Colors
    --color-primary-bg: #{$color-primary-bg};
    --color-primary-fg: #{$color-primary-fg};
    --color-secondary-bg: #{$color-secondary-bg};
    --color-secondary-fg: #{$color-secondary-fg};
    --color-default-bg: #{$color-default-bg};
    --color-default-fg: #{$color-default-fg};
    --color-label-fg: #{$color-label-fg};

    // Breakpoints
    // --breakpoint-desktop-wide: #{$breakpoint-desktop-wide};
    --breakpoint-desktop: #{$breakpoint-desktop};
    --breakpoint-touch: #{$breakpoint-touch};
    --breakpoint-pad: #{$breakpoint-pad};

    // Common scale for mobile
    --scale: 1;

    // Header
    --header-height: 3rem;
    --header-menu-side: var(--header-height);

    // Menu
    --menu-width-desktop: 210px; // не удалять, это максимум для touch
    --menu-width: 50vw;
}

@media screen and (pointer: coarse) {
    :root {
        --scale: 1.25;
    }
}

@media screen and (min-width: $breakpoint-pad) {
    :root {
        --header-height: 3.5rem;
        --menu-width: 3.1rem;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    :root {
        --menu-width: 210px;
        --max-width-container: 1110px;
        --header-menu-side: var(--menu-width);
    }
}

@media screen and (min-width: $breakpoint-desktop-wide) {
    :root {
        --max-width-container: 1250px;
    }
}

#{$class-body-compact-design} {
    --header-height: 3rem !important;
}
