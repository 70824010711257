@import './colors.scss';
@import './sizes.scss';

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin flex($direction, $wrap: nowrap) {
    display: flex;
    flex-flow: $direction $wrap;
}

@mixin flex-center {
    @include flex(row);
    justify-content: center;
    align-items: center;
}

@mixin apply-themes($themes) {
    @each $name, $rules in $themes {
        &__#{$name} {
            @each $name, $value in $rules {
                #{$name}: $value;
            }
        }
    }
}

$breakpoints: (
    touch: $breakpoint-touch,
    pad: $breakpoint-pad,
    desktop: $breakpoint-desktop,
    desktop-wide: $breakpoint-desktop-wide,
);

@mixin respond-to($width, $type: min) {
    @if (map_has_key($breakpoints, $width)) {
        $width: map_get($breakpoints, $width);

        @if ($type == max) {
            $width: $width - 1;
        }

        @media (#{$type}-width: $width) {
            @content;
        }
    }
}

@function str-replace($string, $search, $replace: '') {
    $string: '' + $string;
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function get-color-for-svg-data-url($color) {
    @return str-replace($color, '#', '%23');
}
