@import '../inc.scss';

body {
    --color-body: #efefef;
    --color-text: #000000;
    --color-primary: #{$color-primary-bg};
    --color-primary-darker: #117871;
    --color-active-element: #117871;
    --color-background: #ffffff;
    --color-secondary: #{$color-secondary-bg};
    --color-gray-lighter: #9e9e9e;
    --color-gray-darker: #424242;
    --color-label: var(--color-gray-darker);
    --color-border-shadow: #e0e0e0;

    // Header
    --color-header: var(--color-primary);

    // Menu
    --color-menu-item-text: #616161;
    --color-menu-item-hover-background: rgba(0, 0, 0, .1);
    --color-menu-item-hover-text: #212121;
    --color-menu-horizontal-line: var(--color-gray-lighter);

    // Error block
    --color-error-background: #ef5350;
    --color-error-border: #f44336;
    --color-error-text: #ffffff;

    // Warning block
    --color-warning-background: #ffecb3;
    --color-warning-border: #ffd54f;
    --color-warning-text: #000000;

    // Info block
    --color-info-background: #a5d6a7;
    --color-info-border: #66bb6a;
    --color-info-text: #000000;

    // Titles/headers
    --color-background-titles: var(--color-background);
    --color-text-titles: var(--color-text);
}

body.theme__dark {
    --color-primary: #0d7a6f;
    --color-body: #1d1d1d;
    --color-text: #ffffff;
    --color-active-element: #009688;
    --color-background: #2a2a2a;
    --color-label: #999999;
    --color-border-shadow: #101010;
    --color-gray-darker: #6a6a6a;

    // Header
    --color-header: #185954;

    // Menu
    --color-menu-item-text: #a8a8a8;
    --color-menu-item-hover-background: rgba(255, 255, 255, .1);
    --color-menu-item-hover-text: #eaeaea;
    --color-menu-horizontal-line: var(--color-gray-darker);

    // Titles/headers
    --color-background-titles: #2d2d2d;
    --color-text-titles: var(--color-text-titles);
}
